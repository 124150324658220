<template>
  <div>
    <PageHeader> </PageHeader>

    <b-card>
      <h6>
        {{ `${$t('fields.result')} (${total})` }}
      </h6>
      <b-row class="mb-3">
        <b-col v-if="isOwner" cols="3">
          <MasterSelectInput
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
        <b-col cols="3">
          <AgentSelectInput
            :master-id="selectedMaster"
            :value="selectedAgent"
            hide-label
            @update="onAgentIdChange"
          />
        </b-col>
        <b-col cols="3">
          <UserAgentSelectInput
            :agent-id="selectedAgent"
            :value="selectedUserAgent"
            hide-label
            @update="onUserAgentIdChange"
          />
        </b-col>
        <b-col cols="3">
          <b-select v-model="selectedType">
            <b-form-select-option value="">เลือกประเภท</b-form-select-option>

            <b-form-select-option
              v-for="(type, typeI) in typeOptions"
              :key="typeI"
              :value="type.value"
              >{{ type.name }}
            </b-form-select-option>
          </b-select>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mb-3">
        <b-col cols="12" md="4">
          <b-input-group>
            <b-form-input
              v-model="search"
              :placeholder="`${$t('fields.search')}...`"
              class="mx-1"
              type="search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="mt-3 mt-md-0" cols="6" md="4">
          <label>ตั้งแต่</label>
          <b-form-group>
            <DateTimePicker v-model="selectedFromDate" />
          </b-form-group>
        </b-col>
        <b-col class="mt-3 mt-md-0" cols="6" md="4">
          <label>ถึง</label>
          <b-form-group>
            <DateTimePicker v-model="selectedToDate" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="text-right mb-3">
        <b-button variant="success" @click="fetchData">
          <i class="uil uil-search"></i>
          ค้นหา
        </b-button>
      </div>
      <!-- Table -->
      <b-table
        :busy="isLoading"
        :fields="fields"
        :items="transactions"
        responsive
        show-empty
      >
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(agent)="data">
          <span v-if="data.value.name">
            {{ data.value.name }}
          </span>
          <b-badge v-else variant="danger">
            <i class="uil uil-times-circle"></i>
            {{ $t('agent.no_agent') }}
          </b-badge>
        </template>
        <template #cell(amount)="data">
          <span :class="`text-${displayCreditTypeColor(data.item.type)}`">
            <span v-if="displayCreditTypeColor(data.item.type) === 'success'">
              +
            </span>
            <span
              v-else-if="displayCreditTypeColor(data.item.type) === 'danger'"
            >
              -
            </span>
            {{ data.value | currency }}
          </span>
        </template>
        <template #cell(beforeAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(afterAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(type)="data">
          <b-badge :variant="displayCreditTypeColor(data.value)">
            {{ displayCreditType(data.value) }}
          </b-badge>
        </template>
        <template #cell(creator)="data">
          {{ data.value.username || '-' }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-row align-v="center" class="mb-3">
        <b-col cols="4">
          <LimitSelect v-model="selectedLimit" />
        </b-col>
        <b-col>
          <PaginationInput
            :per-page="limit"
            :total="total"
            @update="(val) => (currentPage = val)"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'รายงานเครดิตเอเย่นต์',
  },
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentSelectInput: () =>
      import('@components/agents/agents-select-input.vue'),
    UserAgentSelectInput: () =>
      import('@components/user-agent/user-agent-select-input.vue'),
  },
  data() {
    return {
      search: '',
      onSearchTimeout: '',
      selectedMaster: '',
      selectedAgent: '',
      selectedUserAgent: '',
      selectedFromDate: '',
      selectedToDate: '',
      selectedType: '',
      typeOptions: [
        { name: 'ทั้งหมด', value: 'ALL' },
        { name: 'ยอดฝาก', value: 'DEPOSIT' },
        { name: 'ยอดถอน', value: 'WITHDRAW' },
      ],
      currentPage: 1,
      selectedLimit: 20,
      fields: [
        {
          key: 'createdAt',
          label: this.$t('fields.issued_date'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'agent',
          label: this.$t('agent.name'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'type',
          label: this.$t('credits.type'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'beforeAmount',
          label: this.$t('credits.before_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'afterAmount',
          label: this.$t('credits.remain_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'creator',
          label: 'ผู้กระทำ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'detail',
          label: `${this.$t('fields.remark')}`,
          thStyle: {
            minWidth: '250px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.credit.isFetchingAgentCreditLogs,
    }),
    ...mapGetters(['isOwner', 'agentCredits', 'userInfo']),
    userId() {
      return this.userInfo.agentId
    },
    transactions() {
      return this.agentCredits.items || []
    },
    pagination() {
      return this.agentCredits.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems || 0
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
    selectedType(val) {
      if (val) {
        this.fetchData()
      }
    },
    selectedDate() {
      this.fetchData()
    },
  },
  created() {
    this.fetchDataAssistants()
  },
  methods: {
    ...mapActions(['fetchAgentCreditLogs', 'fetchAssistantsForInput']),
    fetchData() {
      this.fetchAgentCreditLogs({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        masterId: this.selectedMaster,
        agentId: this.selectedAgent,
        userAgentId: this.selectedUserAgent,
        type: this.selectedType,
        from: this.selectedFromDate || '',
        to: this.selectedToDate || '',
      })
    },
    fetchDataAssistants() {
      this.fetchAssistantsForInput()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onUserAgentIdChange(userAgentId) {
      this.selectedUserAgent = userAgentId
      this.$emit('on-user-agent-change', userAgentId)
      this.fetchData()
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    displayCreditType(type) {
      switch (`${type}`) {
        case '0':
          return 'ยูสฝาก (-)'
        case '1':
          return 'ยูสถอน (+)'
        case '2':
          return 'ฝากตรงให้ยูสเซอร์ (-)'
        case '3':
          return 'ถอนตรงให้ยูสเซอร์ (+)'
        case '4':
          return 'ซื้อเครดิตจากมาสเตอร์ (+)'
        case '5':
          return 'ขายเครดิตให้เอเจ้น (-)'
        default:
          return `${type}`
      }
    },
    displayCreditTypeColor(type) {
      switch (`${type}`) {
        case '1':
        case '3':
        case '4':
          return 'success'
        case '0':
        case '2':
        case '5':
          return 'danger'
        default:
          return 'light'
      }
    },
  },
}
</script>